import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ContainerColumn } from '@components/styled';
import ApiKeysManagement from './ApiKeysManagement';
import MainSettings from './MainSettings';
import BlockContainer from '../../components/BlockContainer';
import ProfileManagement from './ProfileManagement';
const Container = styled(ContainerColumn)((props) => ({
    width: 'auto',
    height: 'auto',
    gap: `${props.theme.spacing_sizes.xs * 2.5}px`,
}));
const MainSettingsBlockContainer = styled(BlockContainer)((props) => ({
    gap: props.theme.spacing_sizes.m,
}));
const PersonalInformation = () => {
    const { t } = useTranslation();
    return (_jsxs(Container, { children: [_jsx(MainSettingsBlockContainer, { title: t('settings.tabs.personal_info.main_table.title'), children: _jsx(MainSettings, {}) }), _jsx(BlockContainer, { title: t('settings.tabs.personal_info.api_keys.title'), children: _jsx(ApiKeysManagement, {}) }), _jsx(BlockContainer, { title: t('settings.tabs.personal_info.profile_management_table.title'), children: _jsx(ProfileManagement, {}) })] }));
};
export default PersonalInformation;
